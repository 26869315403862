@keyframes slide {
    0% {
        background-position: 0 0;
    }
    100% { 
        background-position: 50px 50px;
    }
}

.backgroundpro, html{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    will-change: background-position;
    background-image: 
        linear-gradient(90deg, rgba(255,255,255,0.2) 1px, transparent 1px),
        linear-gradient(180deg, rgba(255,255,255,0.2) 1px, transparent 1px);
    background-size: 50px 50px; /* Size of the grid squares */
    animation: slide 3s linear infinite; /* Adjust time as needed */
}

.project-list {
    width: 80%;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center;
    flex-direction: row; 
    align-items: center;
    top: 10%; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    position: relative;
}

.project {
    width: 100%; /* Subtract margin from 50% width to fit 2 items per row */
    margin: 5px; /* Adjust margin as needed for spacing between projects */
    max-width: 45%;
    display: flex;
    flex-direction: column;
}

.project-headings {
    display: flex;
    font-size: x-large;
    flex-direction: row; /* Changes the flex direction to column */
    justify-content: space-between; /* Aligns items to the start vertically */
    align-items:flex-end; /* Keeps items centered horizontally */
    margin: 10px;
    height: auto; /* Changed to auto to accommodate varying content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 5px; /* Adds some padding inside the project boxes */
    max-width: 400px;
}

.project-description {
    width: 400px;
    margin-bottom: 20px;
    display: flex;
    flex-direction:row; /* Changes the flex direction to column */
    justify-content:center;/* Aligns items to the start vertically */
    align-items: center; /* Keeps items centered horizontally */
    text-align: center; /* Centers the text if desired */
    margin-top: 10px; /* Adds space between the project title and the description */
    color: #fff; /* Sets the text color for the description */
    font-size: medium; /* Adjusts the font size of the description */
}

.header-pro{
    text-align: center; 
    margin-top: 2%; 
    font-size: xx-large;/* Ensures the header is at the very top */
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 70px;
}

.ex-back{
    color: #fff;
    margin: 0%;
  }

  .header-sub{
    padding: 0 25% 0 25%;
  }
  
.loader,
.loader:before,
.loader:after{
    padding-left: 25%;
  justify-content: center;
  align-items: center;
  width: 35px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px inset #fff;
  position: relative;
  animation: l6 1.5s infinite 0.5s;
}
.loader:before,
.loader:after{
  content: "";
  position: absolute;
  left: calc(100% + 5px);
  animation-delay: 1s;
}
.loader:after{
  left: -40px;
  animation-delay: 0s;
}
@keyframes l6 {
  0%,55%,100%  {border-radius:0  }
  20%,30%      {border-radius:50%}
}

@media(max-width: 768px) {
    .project-list{
        top: 10%;
    }
    .project {
        top:0%;
        max-width: 100%; /* Projects take full width on small screens */
    }
    .project-headings{
        align-items: start;
        flex-direction: column; /* Stack elements vertically in project headings and descriptions */
    }
    .project-description {
        padding-right: 15%;
    }
    .header-pro{
        width: 70%;
        left: 0; right: 10;
        background-color: rgb(77, 72, 72);
        position: absolute;
    }
    .header-sub{
        padding: 10px 30px 10px 10px;
    }
    .loader{
        margin-left: 75%;
    }
    .backgroundpro{
        height: 400vh;
        width: 100%;
        max-width: 100vw;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}