html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
  font-size: large;
}

body {
  margin: 0;
  font-family: "Share Tech Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',"Share Tech Mono"
    monospace;
}


*,
*:after,
*:before {
  box-sizing: border-box;
}

