.boxie {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sks-logos-container {
    display: flex;
    flex-wrap: nowrap; /* Prevents logos from wrapping to the next line */
    overflow-x: auto; /* Enables horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Improves scrolling on touch devices */
    scrollbar-width: none; /* Hides scrollbar for Firefox */
    background-color: #99b4be;
}

.sks-logo {
    flex: 0 0 auto; /* Prevents logos from shrinking and sets the basis to auto */
    margin: 25px;
    width: 100px;
}
.sdown{
    justify-content: center;
    display: flex;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.sks-logos-container::-webkit-scrollbar {
    display: none;
    background-color: #99b4be;
}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
    .sks-logos-container {
        flex-wrap: wrap; /* Allows logos to wrap in multiple lines on larger screens */
        justify-content: center; /* Centers logos */
    }
}

/* HTML: <div class="sksplayt"></div> */
.sksplay {
    width: 100%;
    height: 50px;
    background:
      radial-gradient(circle 11px at top,#0000 94%,#b0e5f3) 0 20px,
      radial-gradient(circle 11px at top,#0000 94%,#5dc1e4) 0 10px,
      radial-gradient(circle 11px at top,#0000 94%,#008cd9) 0 0   ;
    background-size: 20px 100%;
    background-repeat: repeat-x;
    animation: l7 1s infinite linear;
  }
  @keyframes l7 {
    50%  {background-position: 10px 15px,-10px 12px,15px 8px}
    100% {background-position: 20px 20px,-20px 10px,20px 0px}
  }

  /* HTML: <div class="sksplayt"></div> */
.sksplayt {
    width: 100%;
    height: 40px;
    background:
      radial-gradient(circle 25px at top right, #ffd738 40%,#0000 ),
      #6a8a94;
    position: relative;
    overflow: hidden;
  }
  .sksplayt:before,
  .sksplayt:after{
    content: "";
    position: absolute;
    top: 4px;
    left: -40px;
    width: 36px;
    height: 20px;
    --c: radial-gradient(farthest-side,#fff 96%,#0000);
    background: 
      var(--c) 100% 100% /30% 60%, 
      var(--c) 70% 0 /50% 100%, 
      var(--c) 0 100% /36% 68%, 
      var(--c) 27% 18% /26% 40%,
      var(--c) 60% 0 /50% 90%, 
      var(--c) 0 100% /36% 68%, 
      var(--c) 27% 18% /26% 40%,
      linear-gradient(#fff 0 0) bottom/67% 58%;
    background-repeat: no-repeat;
    animation: l10 20s linear infinite;
  }
  .sksplayt:after {
     top:15px;
     --l:200%;
  }
  @keyframes l10{
    100% {left:var(--l,105%)}
  }